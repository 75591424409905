import React from 'react'
import Footer from '../../containers/Footer'
import { useHistory } from "react-router-dom";
const Signup = () => {
    const history=useHistory()
    return (
      <div className="loginScreen" style={{height:"100vh" , backgroundImage:"url(images/bg.png)"}}>

      
      <div className="loginScreen_body" >
          <div className="loginScreen_container">
              
          <div className="loginScreen_logo">
          <img style={{width:'100%',textAlign:'center',marginLeft:20}} className="logo" src="images/logo.png" alt=""/>

          </div>
          <div className="signup_input_container">
          <label htmlFor="">Phone number:</label>
          <input  id="ph_nb" name="ph_nb"   type="number" placeholder="Enter your phone number"/>
          </div>
          <div className="signup_input_container">
           <label htmlFor="">Code:</label>
          <input  id="code" name="code"   type="number" placeholder="Enter your code"/>
          </div>
<button>
  SignUp
</button>
<button className="loginScreen_guest" onClick={()=>history.push('/home')}>
  Continue as guest
</button>

              <span className="loginScreen_signUp_btn">
            
              <div className="loginScreen_signUp" onClick={()=>history.push('/login')} >
             Already a user?  Login Now
              </div>
              </span>
            

              </div>
  
              </div>
<Footer />
</div>
  )
}

export default Signup
