import React from "react";
import "./Footer.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import i18n from "../i18n";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_container">
        <div className="footer_links_container">
          <h3> {i18n.t("Address")}</h3>
          <ul>
            <li>
              {i18n.t(
                "Shuwaikh, block 1, street 12, building 71, shop number 19B"
              )}
            </li>
            <li>Lineupfit</li>
          </ul>
        </div>
        <div className="footer_links_container">
          <h3> {i18n.t("Follow Us on")}</h3>

          <ul>
            <li className="footer_social_container">
              {/*<a href="#">
                <FacebookIcon className="footer_social_img" />
              </a>*/}
              <a href="https://instagram.com/lineupfit_kw">
                <InstagramIcon className="footer_social_img" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
