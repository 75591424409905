import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCvicbNNk5Lpi6hwawBFIydl02fp7w-kT4",
    authDomain: "lineupfit-8e3e6.firebaseapp.com",
    projectId: "lineupfit-8e3e6",
    storageBucket: "lineupfit-8e3e6.appspot.com",
    messagingSenderId: "19635681189",
    appId: "1:19635681189:web:39679bbd62252461ef5a4c",
    measurementId: "G-K033CGER70"
  };


  export function InitializeFirebase(){
      if(firebase.apps.length == 0){
        firebase.initializeApp(firebaseConfig);
      }
  }