import React, {useState} from "react";
import "./login.css";
import Footer from "../../containers/Footer";
import { TextField } from "@material-ui/core";
import { Primary } from "../../theme/Theme";
import { useHistory } from "react-router-dom";
const Login = () => {
  const history = useHistory();

  const [phoneNumber, setPhoneNumber] = useState();
  const [code, setCode] = useState();
  const [codeSent, setCodeSent] = useState(false);
  return (
    <div
      className="loginScreen"
      style={{ height: "100vh", backgroundImage: "url(images/bg.png)" }}
    >
      <div className="loginScreen_body">
        <div className="loginScreen_container">
          <div className="loginScreen_logo">
            <img
              style={{ width: "100%", textAlign: "center", marginLeft: 20 }}
              className="logo"
              src="images/logo.png"
              alt=""
            />
          </div>
          {/* <TextField
            size="small"
          id="outlined-ph_nb"
          label="Phone number"
          variant="filled"
          color={Primary}
          onChange={event=>{
            console.log(event.target.value);
          }}
        />*/}
<div className="signup_input_container">
          <label htmlFor="ph_nb">Phone number:</label>
          <input
            id="ph_nb"
            name="ph_nb"
            type="number"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(event) => {
              if(event.target.value.length<=8){
                setPhoneNumber(event.target.value);
              }
            }}
          />
</div>
{codeSent && <button className="loginScreen_guest">Resend code</button>}
{codeSent && <div className="signup_input_container">
          <label htmlFor="code">Code:</label>
          <input
            id="code"
            name="code"
            type="number"
            placeholder="Enter your code"
          />
          </div>}
          <button className="loginScreen_guest" onClick={() => setCodeSent(true)}>Login</button>

          <button
            className="loginScreen_guest"
            onClick={() => history.push("/home")}
          >
            Continue as a guest
          </button>

          <span className="loginScreen_signUp_btn">
            <div
              className="loginScreen_signUp"
              onClick={() => history.push("/signup")}
            >
              New to Lineupfit? Sign Up Now
            </div>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
