import { reactLocalStorage } from "reactjs-localstorage";
import i18n from "../i18n";
import ReactPixel from 'react-facebook-pixel';

export const initialState = {
  categories: [],
  menu: [],
  basket: [],
  product_quantity: 0,
  total_amount: 0,
  areas: [],
  blocks: [],
  user: {},
  orderType: "Delivery",
  address: {},
  dateTime: {},
  checkoutNote: "",
  openingHours: {
    from: '12:00',
    to: '22:00'
  },
  language: 'en'
};

export const getBasketTotal = (basket) => {
  let total = 0;
  let i = 0;
  for (i = 0; i < basket.length; i++) {
    total =
      total +
      basket[i].count *
        (basket[i].offer ? basket[i].sellingPrice : basket[i].originalPrice);
  }
  total = (total * 100) / 100;
  return total;
};
//basket?.reduce((amount, item) => (item.offer? item.sellingPrice : item.offerPrice) * item.quantity + amount, 0);

const reducer = (state, action) => {
  let newBasket = state.basket;
  let proQuantity = state.product_quantity;
  switch (action.type) {
    case "ADD_TO_BASKET":
      newBasket = state.basket;
      proQuantity = state.product_quantity;
      newBasket = [...state.basket, action.item];
      proQuantity = proQuantity + 1;
      reactLocalStorage.setObject("BASKET", newBasket);
      reactLocalStorage.setObject("TOTAL_QUANTITY", {
        product_quantity: proQuantity,
      });

      return {
        ...state,
        basket: newBasket,
        product_quantity: proQuantity,
      };
    case "LOAD_BASKET_FROM_STORAGE":
      newBasket = reactLocalStorage.getObject("BASKET");
      proQuantity =
        reactLocalStorage.getObject("TOTAL_QUANTITY")?.product_quantity || 0;
if(proQuantity<0){proQuantity=0;}
      if (!Array.isArray(newBasket)) {
        newBasket = [];
      }
      return {
        ...state,
        basket: newBasket,
        product_quantity: proQuantity,
      };
    case "REMOVE_FROM_BASKET":

      newBasket = state.basket;
      let item = newBasket.find((x) => x.id == action.item?.id);
      newBasket = state.basket.filter((x) => x.id !== action.item?.id);
      proQuantity = state.product_quantity - item.count;
      if(proQuantity<0){proQuantity=0;}
      reactLocalStorage.setObject("BASKET", newBasket);
      reactLocalStorage.setObject("TOTAL_QUANTITY", {
        product_quantity: proQuantity,
      });

      return {
        ...state,
        basket: newBasket,
        product_quantity: proQuantity,
      };
    case "INCREASE_QUANTITY":
      newBasket = state.basket;
      if (isAvailableInBasket(state.basket, action.item.id)) {
        newBasket = [
          ...state.basket.map((x) => {
            if (x.id == action.item.id) {
              return { ...x, count: x.count + 1 };
            } else {
              return x;
            }
          }),
        ];
      } else {
        newBasket = [...state.basket, action.item];
        ReactPixel.track('AddToCart', { content_type: 'product', contents: action.item, user: state.basket.user, eventDate: new Date()});
      }

      proQuantity = state.product_quantity + 1;
      reactLocalStorage.setObject("BASKET", newBasket);
      reactLocalStorage.setObject("TOTAL_QUANTITY", {
        product_quantity: proQuantity,
      });

      return {
        ...state,
        basket: newBasket,
        product_quantity: proQuantity,
      };
    case "DECREASE_QUANTITY":
      newBasket = state.basket;
      newBasket = [
        ...state.basket
          .map((x) => {
            if (x.id == action.item.id) {
              let count = x.count - 1;
              return { ...x, count };
            } else {
              return x;
            }
          })
          .filter((y) => y.count > 0),
      ];
      proQuantity = state.product_quantity - 1;
      if(proQuantity<0){proQuantity=0;}
      reactLocalStorage.setObject("BASKET", newBasket);
      reactLocalStorage.setObject("TOTAL_QUANTITY", {
        product_quantity: proQuantity,
      });

      return {
        ...state,
        basket: newBasket,
        product_quantity: proQuantity,
      };
    case "CLEAR_BASKET":
      reactLocalStorage.setObject("BASKET", []);
      reactLocalStorage.setObject("TOTAL_QUANTITY", {
        product_quantity: 0,
      });
      return {
        ...state,
        basket: [],
        product_quantity: 0,
      };
      case "SET_CATEGORIES":
      return { ...state, categories: action.categories };
    case "SET_MENU":
      return { ...state, menu: action.menu };
    case "SET_AREAS":
      reactLocalStorage.setObject("AREAS", action.areas);
      return { ...state, areas: action.areas };
    case "LOAD_AREAS_FROM_STORAGE":
      let newAreas = reactLocalStorage.getObject("AREAS");

      if (!Array.isArray(newAreas)) {
        newAreas = [];
      }
      return {
        ...state,
        areas: newAreas,
      };

    case "SET_BLOCKS":
      return { ...state, blocks: action.blocks };
    case "LOAD_BLOCKS_FROM_STORAGE":
      let newBlocks = reactLocalStorage.getObject("BLOCKS");

      if (!Array.isArray(newBlocks)) {
        newBlocks = [];
      }
      return {
        ...state,
        blocks: newBlocks,
      };

    case "SAVE_USER_TO_STORAGE":
      reactLocalStorage.setObject("USER", action.user);
      return { ...state, user: action.user };
    case "LOAD_USER_FROM_STORAGE":
      let usr = reactLocalStorage.getObject("USER");
      return { ...state, user: usr };

    case "SAVE_ORDERTYPE_TO_STORAGE":
      reactLocalStorage.set("ORDERTYPE", action.orderType);
      return { ...state, orderType: action.orderType };
    case "LOAD_ORDERTYPE_FROM_STORAGE":
      let ordType = reactLocalStorage.get("ORDERTYPE");
      return { ...state, orderType: ordType || 'Delivery'};
    case "SAVE_ADDRESS_TO_STORAGE":
      reactLocalStorage.setObject("ADDRESS", action.address);
      return { ...state, address: action.address };
    case "LOAD_ADDRESS_FROM_STORAGE":
      let add = reactLocalStorage.getObject("ADDRESS");
      return { ...state, address: add };
    
      case "SAVE_DATETIME_TO_STORAGE":
        reactLocalStorage.setObject("DATETIME", action.dateTime);
        return { ...state, dateTime: action.dateTime };
      case "LOAD_DATETIME_FROM_STORAGE":
        let dt = reactLocalStorage.getObject("DATETIME");
        return { ...state, dateTime: dt };
    case "SET_OPENING_HOURS":
      return { ...state, openingHours: action.openingHours };
      case "SAVE_LANGUAGE_TO_STORAGE":
        reactLocalStorage.setObject("LANGUAGE", action.language);
        return { ...state, language: action.language };
      case "LOAD_LANGUAGE_FROM_STORAGE":
        let ln = reactLocalStorage.getObject("LANGUAGE");
        if(ln !=='en' && ln !== 'ar')
        {
          ln = 'en';
        }
        i18n.changeLanguage(ln);
        return { ...state, language: ln };
    
        default:
      return state;
  }
};

const isAvailableInBasket = (basket, id) => {
  return basket?.find((x) => x.id == id) || false;
};
export default reducer;
