import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import store from './state/store';
import { Provider } from 'react-redux';
import { StateProvider } from './state/StateProvider'
import reducer, { initialState } from './state/reducer';

ReactDOM.render(
  <Provider store={store}>
    
    <StateProvider initialState={initialState} reducer={reducer}>

    
    <BrowserRouter>
    
    

        <App />
   
    </BrowserRouter>
    </StateProvider>
  </Provider>,
 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
