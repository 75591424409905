import React, { Component,lazy, useEffect } from 'react';
import { HashRouter,BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { InitializeFirebase  } from './api/firebase';
import Login from './screens/Login';
import Signup from './screens/Signup';
import Address from './screens/Address';
import './i18n';
import { withNamespaces } from 'react-i18next';
import { CircularProgress } from "@material-ui/core";
import { useStateValue } from "./state/StateProvider";
import { GetOpeningHours } from './api/usersAPI';
import ReactPixel from 'react-facebook-pixel';

const Layout = lazy(() => import('./containers/Layout'));

let FACEBOOKPIXELID = '920149208548254';
const advancedMatching = { em: 'scene.pst@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"> <CircularProgress />Loading ...</div>
  </div>
)

function App() {

  const [{ }, dispatch] = useStateValue();
  useEffect(()=>{
    ReactPixel.init(FACEBOOKPIXELID, advancedMatching, options);
  },[])


  /*
  ReactPixel.pageView(); // For tracking page view
ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
ReactPixel.trackSingleCustom('PixelId', event, data);
*/


  useEffect(()=>{
    dispatch({
      type: "LOAD_BASKET_FROM_STORAGE"
    });
  },[]);

  useEffect(()=>{
    async function fetchOpeningHours (){
      
      console.log('FETCHING OPENONG HOURS');
      GetOpeningHours()
      .then((resp)=>{
        console.log('OPENONG HOURS', resp.openingHours);
        dispatch({
          type: "SET_OPENING_HOURS",
          openingHours: resp.openingHours,
        });
      })
      .catch(()=>{
        dispatch({
          type: "SET_OPENING_HOURS",
          action: {
            from: '12:00',
            to: '22:00'
          }
        });
      })
 
  }
  fetchOpeningHours();
  },[]);

  useEffect(()=>{
    dispatch({
      type: "LOAD_LANGUAGE_FROM_STORAGE"
    });
    dispatch({
      type: "LOAD_USER_FROM_STORAGE"
    });
    dispatch({
      type: "LOAD_ORDERTYPE_FROM_STORAGE"
    });
    dispatch({
      type: "LOAD_ADDRESS_FROM_STORAGE"
    });
  },[]);

  InitializeFirebase();
  return (
    <HashRouter>
           <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/" name="Home" render={props => {return (<Layout {...props}/>);}} />
             
            </Switch>
          </React.Suspense>
      </HashRouter> 
     
  );
}

//export default App;
export default withNamespaces()(App);