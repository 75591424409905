import axios from "axios";
import firebase from "firebase";

// let BACKEND_URL = "http://localhost:3001";
let BACKEND_URL = "https://lineupfit.brbackend-node.xyz";
export async function GetOpeningHours() {
  let resp = await axios
    .get(
      BACKEND_URL + "/openinghours",
      { timeout: 10000, "Access-Control-Allow-Origin": "*" }
    )
    .then((apiResp) => {
      console.log('RESP', apiResp.data);
      if (apiResp.status == 200) {
        return apiResp.data;
      } else {
        return {
          openingHours: {
            from: "12:00",
            to: "22:00",
          },
        };
      }
    })
    .catch((error) => {
      console.log('Error1', error);
      return {
        openingHours: {
          from: "12:00",
          to: "22:00",
        },
      };
    });
  return resp;
}

export async function GetAreas() {
  let resp = await axios
    .get(
      BACKEND_URL + "/delivery/localareas",
      {},
      { timeout: 10000, "Access-Control-Allow-Origin": "*" }
    )
    .then((apiResp) => {
      console.log(apiResp.data);
      if (apiResp.status == 200) {
        return apiResp.data.data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  return resp;
}

export async function GetBlocks({ area_id }) {
  if (!area_id) {
    return [];
  }
  let resp = await axios
    .post(
      BACKEND_URL + "/delivery/block",
      { area_id },
      { timeout: 10000, "Access-Control-Allow-Origin": "*" }
    )
    .then((apiResp) => {
      if (apiResp.status == 200) {
        return apiResp.data.data.data;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  return resp;
}

export async function GetDeliveryFees({ area_id, block }) {
  if (!area_id || !block) {
    return 0;
  }
  let resp = await axios
    .post(
      BACKEND_URL + "/delivery/deliveryfees",
      { area_id, block },
      { timeout: 15000 }
    )
    .then((apiResp) => {
      if (apiResp.status == 200) {
        return apiResp.data.data.data?.fare;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
  return resp;
}


export async function addOrder({
  user,
  cart,
  notes,
  address,
  total,
  orderType,
  dateTime,
  delivery,
}) {
  let paymentMethod = 'payByCard';
  

  let order = {};
  order.type = 'dailyOrder';
  order.Items = cart;
  order.notes = notes || null;
  order.orderType = orderType;
  order.dateTime = dateTime || null;
  order.total = total;
  order.delivery = delivery;
  order.currency = 'KD';
  order.address = address || null;
  order.customer = {
    uid: user.uid,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    email: user.email || null,
  };
  order.created_at = firebase.firestore.FieldValue.serverTimestamp();
  order.source = 'website';
  //firebase.firestore.Timestamp.now();
  //firebase.firestore().FieldValue.serverTimestamp();
  order.userId = user.uid;
  order.paymentMethod = paymentMethod;
  if (paymentMethod === 'payOnDelivery') {
    order.paymentStatus = 'payOnDelivery';
  } else {
    order.paymentStatus = 'notPaid';
  }
  console.log('ORDER', order);

  let savedOrderRef = await firebase.firestore().collection('liveOrders').add(order).catch(error => {
    console.log('ORDER', error);
    return false;
  });
  if (!savedOrderRef) {
    return false;
  }

  let savedOrder = await savedOrderRef
    .get()
    .then(ord => {
      return {...ord.data(), uid: ord.id};
    })
    .catch(error => {
      console.log('SavedOrder', error);
      return false;
    });
  if (!savedOrder) {
    return false;
  }
  if (paymentMethod === 'payOnDelivery') {
    return {status: true, orderId: savedOrder.uid};
  }

  //savedOrder.customer = undefined;
  let paymentParams = {};
  paymentParams.order = savedOrder;
  paymentParams.customer = user;
  
  const headers = {
    'Content-Type': 'application/json',
  };
  console.log('axios', BACKEND_URL);
  let resp = await axios
    .post(
      BACKEND_URL + '/payments/makepaymentfromwebsite',
      //BACKEND_URL + '/payments/testpaymentfromwebsite',
      {
        ...paymentParams,
      },
      {headers, timeout: 30000},
    )
    .then(apiResp => {
      console.log('API RESP', apiResp);
      if (apiResp.status == 200) {
        if (apiResp.data.status == 'success') {
          return {url: apiResp.data.paymentURL, orderId: savedOrder.uid};
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
    .catch(error => {
      console.log(error);
      return false;
    });
  return resp;
}

export async function getOrder({orderId}){
  let order = await firebase.firestore()
  .collection('liveOrders')
  .doc(orderId)
  .get()
  .then(snapshot => {
    return snapshot.data();
  })
  .catch(error => {
    return {paymentStatus: 'notPaid'};
  });
return order;
}